import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { useAffiliatesContext } from '@/components/contexts/affiliates';
import { useStoryblokBannersContext } from '@/components/contexts/storyblokBanners';
import { ErrorOverlay } from '@/components/shared/ErrorOverlay';
import { ReferralSchemeBanner } from '@/components/storyblok/bloks/banner-components/ReferralSchemeBanner';
import { RewardBanner } from '@/components/storyblok/bloks/banner-components/RewardBanner';
import { copy } from '@/copy';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import apiClient from '@/services/api-client';
import {
	CreateAffiliateSessionMutation,
	GetAffiliateLinkQuery,
} from '@/services/typed-graphql-sdk';
import { isBrowser } from '@/utils/browser/isBrowser';
import { SALES_CHANNELS_THAT_RECEIVE_D2C_REFERRAL_REWARD } from '@/utils/constants/marketing';

type AffiliateBannerProps = {
	affiliateCode: string;
	// Optional audio recording id that is used for field sales registrations.
	audioRecordingId?: string;
};

const AffiliateBanner: FC<AffiliateBannerProps> = ({
	affiliateCode,
	audioRecordingId,
}) => {
	const router = useRouter();
	const storyblokBannersContext = useStoryblokBannersContext();

	const [, setAffiliateOrganisationName] = useSessionStorage<
		string | undefined
	>('affiliateOrganisationName', '');
	const [affiliateSalesChannel, setAffiliateSalesChannel] = useSessionStorage<
		string | undefined
	>('affiliateSalesChannel', '');

	const [affiliateLinkId, setAffiliateLinkId] = useState<string>();

	const [affiliateSessionCode, setAffiliateSessionCode] = useSessionStorage<
		string | undefined
	>('affiliateCode', '');

	const [, setAffiliateSessionId] = useSessionStorage<string | undefined>(
		'affiliateSessionId',
		''
	);

	const [, setAudioRecordingId] = useSessionStorage<string | undefined>(
		'audioRecordingId',
		''
	);
	const { setIsAffiliatesReady, setSalesChannel } = useAffiliatesContext();

	const [referralSchemeCode, setReferralSchemeCode] = useSessionStorage<
		string | undefined
	>('referralSchemeCode', '');

	const [showErrorOverlay, setShowErrorOverlay] = useState(false);

	const onDismissError = () => {
		const { pathname, query } = router;
		delete router.query.affiliate;
		router.replace({ pathname, query }, undefined, { shallow: true });
		setShowErrorOverlay(false);
	};

	const isThereNoExistingSessionYetForThisAffiliateLink = Boolean(
		affiliateCode && !affiliateSessionCode
	);
	const didUserManuallyUpdateURLWithDifferentAffiliateLinkWhileHavingAnActiveSession =
		Boolean(
			affiliateCode &&
				affiliateSessionCode &&
				affiliateCode !== affiliateSessionCode
		);

	const shouldGetAffiliateLink =
		isThereNoExistingSessionYetForThisAffiliateLink ||
		didUserManuallyUpdateURLWithDifferentAffiliateLinkWhileHavingAnActiveSession;

	const shouldDefaultToD2CBanner =
		SALES_CHANNELS_THAT_RECEIVE_D2C_REFERRAL_REWARD.some(
			(salesChannel) => salesChannel === affiliateSalesChannel
		);

	const affiliateLinkQuery = useQuery({
		queryKey: ['affiliateLink', affiliateCode, shouldGetAffiliateLink],
		queryFn: async () => {
			return await apiClient.post<GetAffiliateLinkQuery>(
				'/api/onboarding/get-affiliate-link',
				{ subdomain: affiliateCode || affiliateSessionCode }
			);
		},
		enabled: shouldGetAffiliateLink,
		refetchOnWindowFocus: false,
	});

	const { data, isError } = affiliateLinkQuery;

	useEffect(() => {
		if (data?.affiliateLink) {
			const { activeAffiliateReferralScheme, affiliateLink } = data;
			const params = new URLSearchParams(window.location.search);
			/**
			 * Prefer the referralSchemeCode from URL params over the affiliate linked scheme.
			 * We use this for the win-back reward campaign.
			 * Example link: /join?affiliate=win-back-8888&referralSchemeCode=WIN-BACK-8888
			 */
			const referralSchemeCodeFromParamsOrContext =
				(params.get('referralSchemeCode') ||
					activeAffiliateReferralScheme?.code) ??
				'';
			setReferralSchemeCode(referralSchemeCodeFromParamsOrContext);
			setAffiliateLinkId(affiliateLink.id);
			setAffiliateSalesChannel(affiliateLink.organisation?.salesChannel ?? '');
			setAffiliateOrganisationName(affiliateLink.organisation?.name ?? '');
			audioRecordingId && setAudioRecordingId(audioRecordingId);
			setSalesChannel(affiliateLink.organisation?.salesChannel ?? '');
			setIsAffiliatesReady(true);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			setShowErrorOverlay(true);
			setTimeout(onDismissError, 15000);
		}
	}, [isError]);

	const createAffiliateSessionQuery = useQuery({
		queryKey: ['createAffiliateSession', affiliateLinkId],
		queryFn: async () => {
			const { ipAddress } = await apiClient.get<{ ipAddress: string }>(
				'/api/get-ip'
			);
			return await apiClient.post<CreateAffiliateSessionMutation>(
				'/api/onboarding/create-affiliate-session',
				{
					input: {
						linkId: affiliateLinkId,
						ipAddress,
						...(isBrowser() ? { userAgent: navigator.userAgent } : {}),
						...(audioRecordingId
							? {
									queryParams: JSON.stringify({
										audio_recording_id: audioRecordingId,
									}),
								}
							: {}),
					},
				}
			);
		},
		// The query will not execute until the affiliateLinkId exists
		enabled: Boolean(affiliateLinkId),
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (createAffiliateSessionQuery.data?.createAffiliateSession) {
			const {
				createAffiliateSession: { affiliateSession },
			} = createAffiliateSessionQuery.data;
			setAffiliateSessionCode(affiliateCode ?? affiliateSessionCode);
			setAffiliateSessionId(affiliateSession?.id);
		}
	}, [createAffiliateSessionQuery.data]);

	/**
	 * Loading state, display nothing as no active banner may be resolved from queries.
	 */
	if (affiliateLinkQuery.isLoading || createAffiliateSessionQuery.isLoading) {
		return <></>;
	}

	const bannerStory =
		storyblokBannersContext.bannerStories.find(
			(story) => story.slug === referralSchemeCode
		) ??
		storyblokBannersContext.bannerStories.find(
			(story) => story.slug === affiliateCode
		);

	return (
		<>
			{showErrorOverlay && (
				<ErrorOverlay
					message={
						<>
							<strong>{copy.invalidUrl}</strong>
							<span className="block">{copy.invalidAffiliateLink}</span>
						</>
					}
					buttonText={copy.close}
					onClick={onDismissError}
				/>
			)}
			{affiliateLinkQuery.status === 'success' || affiliateSessionCode ? (
				bannerStory?.content ? (
					<RewardBanner
						data-testid="affiliate reward banner"
						aria-label="affiliate reward banner"
						content={bannerStory?.content}
					/>
				) : shouldDefaultToD2CBanner ? (
					/**
					 * The affiliate link does not have an associated banner story.
					 * In this case, display the current signup reward banner.
					 */ <ReferralSchemeBanner />
				) : null
			) : (
				/**
				 * Error state, display nothing. Error messages are handled by Toast or Sentry.
				 */
				<></>
			)}
		</>
	);
};

export { AffiliateBanner };

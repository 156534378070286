import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { useAffiliatesContext } from '@/components/contexts/affiliates';
import { useAuthContext } from '@/components/contexts/auth';
import { AffiliateBanner } from '@/components/storyblok/bloks/banner-components/AffiliateBanner';
import { ReferralSchemeBanner } from '@/components/storyblok/bloks/banner-components/ReferralSchemeBanner';
import { useReadSessionStorage } from '@/hooks/useReadSessionStorage';
import { useSessionStorage } from '@/hooks/useSessionStorage';

export const RewardBannerHandler: FC = () => {
	const affiliateSessionCode = useReadSessionStorage<string | null>(
		'affiliateCode'
	);
	const router = useRouter();
	const [shouldShowAffiliateBanner, setShouldShowAffiliateBanner] =
		useState(false);
	const [shouldShowReferralBanner, setShouldShowReferralBanner] =
		useState(false);
	const [
		referralSchemeCodeFromQueryParams,
		setReferralSchemeCodeFromQueryParams,
	] = useState<string>();
	const [referralSchemeCodeFromSS] = useSessionStorage<string | undefined>(
		'referralSchemeCode',
		''
	);
	const referralSchemeCodeFromSSOrQueryParams =
		referralSchemeCodeFromQueryParams || referralSchemeCodeFromSS;
	const { isLoggedIn, isCheckingAuthentication } = useAuthContext();
	const { setIsAffiliatesReady } = useAffiliatesContext();

	useEffect(() => {
		if (router.query && !isCheckingAuthentication) {
			setShouldShowAffiliateBanner(
				Boolean(router.query?.affiliate || affiliateSessionCode)
			);
			if (!router.query?.affiliate && !affiliateSessionCode) {
				// no affiliate to handle, set flag to true so banners can be rendered
				setIsAffiliatesReady(true);
			}
			setShouldShowReferralBanner(!isLoggedIn && !router.query?.referral);

			const params = new URLSearchParams(window.location.search);
			const referralSchemeCode = params.get('referralSchemeCode');
			if (referralSchemeCode) {
				setReferralSchemeCodeFromQueryParams(referralSchemeCode);
			}
		}
	}, [router, isLoggedIn, isCheckingAuthentication, affiliateSessionCode]);

	return (
		/**
		 * Reserve space for the Banner components, preventing cumulitive layout shift.
		 * see: https://developers.google.com/publisher-tag/guides/minimize-layout-shift#choosing_the_right_approach
		 */
		<div className="mb-4 w-full" style={{ minHeight: 60 }}>
			{shouldShowAffiliateBanner ? (
				<AffiliateBanner
					affiliateCode={(router.query?.affiliate as string)?.toLowerCase()}
					audioRecordingId={router.query?.audio_recording_id as string}
				/>
			) : shouldShowReferralBanner ? (
				<ReferralSchemeBanner
					referralSchemeCode={referralSchemeCodeFromSSOrQueryParams}
				/>
			) : null}
		</div>
	);
};
